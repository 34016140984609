import { useIsMutating, useQueryClient } from '@tanstack/react-query';
import React, { FC, useEffect } from 'react';
import {
  useCreate,
  useDelete,
  useReadAll,
  useUpdate,
} from '@/lib/services/api-config/crud';

import { Table } from '@phpcreation/frontend-components-react';
import useListingColumns from '@/hooks/shared/listing/useListingColumns';
import { Field, IFQCN_BUI } from '@/utils/types/common';
import useModalsStore from '@/lib/global-stores/shared/modals/useModalsStore';
import useListingStore from '@/lib/global-stores/shared/listing/useListingStore';
import { useRouter } from 'next/router';
import { getFQCN } from '@/lib/helpers/fqcn/methods';


type Props = {
  resource: string;
  defaultColumns: Field[];
  fqcn_bui: IFQCN_BUI;
  id: string;
};

const TableListingSide: FC<Props> = ({
  resource,
  defaultColumns,
  id,
  fqcn_bui,
}) => {
  const { closeAllModals } = useModalsStore();
  const { fields, filters, selectedActions, setPage, setPageSize } =
    useListingStore();
    const router = useRouter();
  const {
    isLoading: issLoading,
    isError,
    isSuccess,
    data,
    refetch,
  } = useReadAll(
    resource,
    { pageSize: 10, specific: { tenant: id?.toString() } },
    true
  );

  const queryClient = useQueryClient();

  const ismutating = useIsMutating();

  useEffect(() => {
    const TO = setTimeout(() => {
      closeAllModals();
    }, 600);

    return () => clearTimeout(TO);
  }, [ismutating]);

  useEffect(() => {
    console.log('hello', ismutating);
  }, [ismutating]);

  return (
    <div className="border rounded-md border-gray-100 p-1"  id={id}>
      <Table
      id={getFQCN(fqcn_bui, 'list')}
        rowKey="id"
        
        
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              if (event.ctrlKey) {
                window.open('/' + resource + '/' + record.id, "_blank")
              } else {
              router.push('/' + resource + '/' + record.id);}
            }, // click row
          };
        }}
        columns={useListingColumns(resource, defaultColumns, fqcn_bui, true)}
        // columns={useListingColumns(resource).filter(col =>
        //   fields?.includes(col.key)
        // )}
        loading={ismutating == 1}
        dataSource={
          data
            ? [
                ...data['hydra:member'].map((e: any) => ({
                  ...e,
                  selectedActions,
                })),
              ]
            : []
        }
        onChange={(pagination, filters, sorter) => {
          console.log(pagination);
          setPage(pagination.current);
          setPageSize(pagination.pageSize ? pagination.pageSize : 10);
        }}
        pagination={{
          pageSize: filters.pageSize ? filters.pageSize : 10,
          current: filters.page ? filters.page : 1,
          showSizeChanger: true,
          total: data && data['hydra:totalItems'],
          pageSizeOptions: [5, 25, 100, 500, 1000],
          position: ['bottomLeft'],
        }}
      />
    </div>
  );
};

export default TableListingSide;
