import { Button, Select, Input, PageLayout, LinkButton, Toggle, RichTextEditor, TextArea } from '@phpcreation/frontend-components-react';
import { getFQCN } from '@/lib/helpers/fqcn/methods';
import { submitDataModifier } from '@/lib/helpers/methods';
import { get_all_resource, useCreate } from '@/lib/services/api-config/crud';
import {
  FormInput,
  FormInputItem,
  IFQCN_BUI,
  Navigation,
} from '@/utils/types/common';
import _ from 'lodash';
import { useRouter } from 'next/router';
import React, { FC, useEffect, useState } from 'react';
import useTranslation from '@/hooks/shared/translation/useTranslation';
import Link from 'next/link';
import parse from 'html-react-parser';

type CreateProps = {
  resource: string;
  appNav: Navigation[];
  formInputs: FormInputItem[];
  fqcn_bui: IFQCN_BUI;
  selectedList?: string [] | undefined;

};

const Create: FC<CreateProps> = ({
  resource,
  appNav,
  formInputs,
  fqcn_bui,
  selectedList
}) => {
  const router = useRouter();

  const { t } = useTranslation();

  //@ts-ignore
  const {
    data: dataCreate,
    isLoading: isLoadingCreate,
    isSuccess: isSuccessCreate,
    mutate: create_resource,
  } = useCreate(resource);

  const [FormData, setFormData] = useState<any>({});
  const [check, setCheck] = useState(false);

const findRequiredField = (selectedList:string[],label:string) => {
  for (let i = 0; i <= selectedList.length; i++) {
    if (selectedList[i] === label) {
      return t(label) + ' *'
    }
  }
  return t(label)
}

  useEffect(() => {
    let key_value_formData = {};
    formInputs.forEach(i => {
      //@ts-ignore
      key_value_formData[i.name] = router.query[i.name] ?? '';
    });
    setFormData(key_value_formData);
  }, [router.isReady]);
  // if (isError) return <p>eror</p>;
  return (
    <PageLayout
      fqcn_bui={fqcn_bui}
      title={t('Add ') + t(resource.toString().slice(0, resource.length - 1))}
      appNav={appNav}
      breadcrumbNav={[
        { label: 'Home', url: '/' },
        { label: resource, url: `/${resource}`},
        { label: 'new', url: '#' },
      ]}
    >
      <div className="py-4">
        <form
          id={getFQCN(fqcn_bui, 'form')}
          className="flex flex-col items-start space-y-1"
        >
          {
            //@ts-ignore
            formInputs.map((field: FormInputItem, index: any) => {
              if (field.type == 'input')
                return (
                  <div
                    key={index}
                    id={getFQCN(fqcn_bui, 'form', field.name)}
                    className="flex flex-col items-start -space-y-2 text-sm"
                  >
                    <label
                      id={getFQCN(fqcn_bui, 'form', field.name + '-label')}
                    >
                      {selectedList != undefined ? findRequiredField(selectedList,field.name) : t(field.label)}
                    </label>

                    <Input
                      id={getFQCN(fqcn_bui, 'form', field.name + '-input')}
                      // size="large"
                      onChange={e =>
                        setFormData({
                          ...FormData,
                          [field.name]: e.target.value,
                        })
                      }
                      value={FormData[field.name] ?? ''}
                      placeholder={t(field.placeholder ? field.placeholder : '')}
                    />
                  </div>
                );
                if (field.type == 'email')
                return (
                  <div
                    key={index}
                    id={getFQCN(fqcn_bui, 'form', field.name)}
                    className="flex flex-col items-start -space-y-2 text-sm"
                  >
                    <label
                      id={getFQCN(fqcn_bui, 'form', field.name + '-label')}
                    >
                      {selectedList != undefined ? findRequiredField(selectedList,field.name) : t(field.label)}
                    </label>

                    <Input
                    type='email'
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" 
                    required
                      id={getFQCN(fqcn_bui, 'form', field.name + '-input')}
                      // size="large"
                      onChange={e => setFormData({
                        ...FormData,
                        [field.name]: e.target.value,
                      })}
                      value={FormData[field.name] ?? ''}
                      placeholder={t(field.placeholder ? field.placeholder : '')}
                    />
                    {/* {errors != '' ? 'mail error' : ''} */}
                  </div>
                );
                if (field.type == 'phone')
                return (
                  <div
                    key={index}
                    id={getFQCN(fqcn_bui, 'form', field.name)}
                    className="flex flex-col items-start -space-y-2 text-sm"
                  >
                    <label
                      id={getFQCN(fqcn_bui, 'form', field.name + '-label')}
                    >
                      {selectedList != undefined ? findRequiredField(selectedList,field.name) : t(field.label)}
                    </label>
                    
                    <Input
                    type='phone'
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" 
                    required
                      id={getFQCN(fqcn_bui, 'form', field.name + '-input')}
                      // size="large"
                      onChange={e => setFormData({
                        ...FormData,
                        [field.name]: e.target.value,
                      })}
                      value={FormData[field.name] ?? ''}
                      placeholder={t(field.placeholder ? field.placeholder : '')}
                    />
                    {/* {errors != '' ? 'mail error' : ''} */}
                  </div>
                );
                //CASE input
                if (field.type == 'text')
                  return (
                  <div
                    key={index}
                    id={getFQCN(fqcn_bui, 'form', field.name)}
                    className="flex flex-col items-start -space-y-2 text-sm"
                  >
                    <label
                      id={getFQCN(fqcn_bui, 'form', field.name + '-label')}
                    >
                      {selectedList != undefined ? findRequiredField(selectedList,field.name) : t(field.label)}
                    </label>
                    <br/>
                    <TextArea
                      id={getFQCN(fqcn_bui, 'form', field.name + '-input')}
                      onChange={e => setFormData({
                        ...FormData,
                        [field.name]: e.target.value,
                      })}
                      value={FormData[field.name] ?? ''} size={'small'}
                    />
                  </div>
                );
                // CASE Number
                if (field.type == 'number')
                return (
                  <div
                    key={index}
                    id={getFQCN(fqcn_bui, 'form', field.name)}
                    className="flex flex-col items-start -space-y-2 text-sm"
                  >
                    <label
                      id={getFQCN(fqcn_bui, 'form', field.name + '-label')}
                    >
                      {selectedList != undefined ? findRequiredField(selectedList,field.name) : t(field.label)}
                    </label>
                    <br/>
                    <Input
                      type='number'
                      id={getFQCN(fqcn_bui, 'form', field.name + '-input')}
                      onChange={e => setFormData({
                        ...FormData,
                        [field.name]: parseInt(e.target.value),
                      })}
                      value={FormData[field.name] ?? ''} size={'small'}
                      />
                  </div>
                );
                       // CASE Date
                if (field.type == 'date')
                return (
                  <div
                    key={index}
                    id={getFQCN(fqcn_bui, 'form', field.name)}
                    className="flex flex-col items-start -space-y-2 text-sm"
                  >
                    <label
                      id={getFQCN(fqcn_bui, 'form', field.name + '-label')}
                    >
                      {selectedList != undefined ? findRequiredField(selectedList,field.name) : t(field.label)}
                    </label>

                    <Input
                      type='date'
                      id={getFQCN(fqcn_bui, 'form', field.name + '-input')}
                      // size="large"
                      onChange={e =>
                        setFormData({
                          ...FormData,
                          [field.name]: e.target.value,
                        })
                      }
                      value={FormData[field.name] ?? ''}
                      placeholder={t(field.placeholder ? field.placeholder : '')}
                    />
                  </div>
                );
              // CASE  Finite
              if (field.type == 'select')
                return (
                  <div
                    id={getFQCN(fqcn_bui, 'form', field.name)}
                    className="flex flex-col items-start -space-y-0 text-sm"
                  >
                    <label
                      id={getFQCN(fqcn_bui, 'form', field.name + '-label')}
                    >
                      {selectedList != undefined ? findRequiredField(selectedList,field.name) : t(field.label)}
                    </label>
                    <Select
                      id={getFQCN(fqcn_bui, 'form', field.name + '-input')}
                      placeholder={t(field.placeholder ? field.placeholder : '')}
                      options={field.optionsArrayFinite}
                      onChange={(newIds: any) => {
                        console.log({ newIds });
                        setFormData({
                          ...FormData,
                          [field.name]: newIds,
                        });
                      }}
                      // label={ FormData[field.name]}
                      value={ FormData[field.name]}
                    />
                  </div>
                );
                // CASE Finite + Boolean
                if (field.type == 'checkbox')
                return (
                  <div
                    id={getFQCN(fqcn_bui, 'form', field.name)}
                    className="flex flex-col items-start -space-y-0 text-sm"
                  >
                    <label
                      id={getFQCN(fqcn_bui, 'form', field.name + '-label')}
                    >
                      {selectedList != undefined ? findRequiredField(selectedList,field.name) : t(field.label)}
                    </label>
                    <Toggle
                    style={{backgroundColor: FormData[field.name] && check  ? 'blue' : 'red'}}
                    checkedChildren="True" 
                    unCheckedChildren="False" 
                    id={getFQCN(fqcn_bui, 'form', field.name + '-input')}
                    onChange={(newIds: any) => {
                      setFormData({
                        ...FormData,
                        [field.name]: newIds,
                      });
                      setCheck(newIds)
                    }}
                    key={FormData[field.name]}
                    defaultChecked={FormData[field.name]}
                    />
                  </div>
                );
              // CASE Not Finite == Load from server
              if (
                field.type == 'select_async' &&
                field.targetResourceAsync?.length
              )
                return (
                  <div
                    id={getFQCN(fqcn_bui, 'form', field.name)}
                    className="flex flex-col items-start -space-y-0 text-sm"
                  >
                    <label
                      id={getFQCN(fqcn_bui, 'form', field.name + '-label')}
                    >
                      {selectedList != undefined ? findRequiredField(selectedList,field.name) : t(field.label)}
                    </label>

                    <Select
                      id={getFQCN(fqcn_bui, 'form', field.name + '-input')}
                      variant="async"
                      placeholder={t(field.placeholder ? field.placeholder : '')}
                      defaultOptions
                      cacheOptions={true}
                      loadOptions={(input, callback) => {
                        get_all_resource(
                          `${field.targetResourceAsync}`,
                          { id: input },
                          false
                        )
                          .then(res =>
                            res.map((i: any) => {
                              return { 
                                label: field.resourceAsyncLabelParam ? parse(i[field.resourceAsyncLabelParam]) : i.id,
                                value: i.id 
                              };
                            })
                          )
                          .then(data => callback(data));
                      }}
                      onChange={(newIds: any) => {
                        console.log({ newIds });
                        setFormData({
                          ...FormData,
                          [field.name]: newIds,
                        });
                      }}
                      value={FormData[field.name]}
                    />
                  </div>
                );
            })
          }
          <div className="flex w-full justify-center space-x-2  p-3">
            <Link href={`/${resource}`} passHref legacyBehavior>
              <LinkButton
                id={getFQCN(fqcn_bui, 'form', 'button-cancel')}
                href={`/${resource}`}
                onClick={() => {}}
                variant="secondary"
                title={t('Cancel')}
              />
            </Link>
            <Button
              variant="secondary"
              color="blue-phpr"
              id={getFQCN(fqcn_bui, 'form', 'button-submit')}
              title={t("Create")}
              isLoading={isLoadingCreate}
              onClick={(event) => {
                event.preventDefault();

                let adjusted_formData = submitDataModifier(
                  FormData,
                  formInputs
                );

                //@ts-ignore
                create_resource(adjusted_formData);
              }}
            />
          </div>
        </form>
      </div>
    </PageLayout>
  );
};

export default Create;
