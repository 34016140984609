import {
  IconGlyphPlus,
  IconLightning,
  IconTenant,
  Button,
  Dropdown,
  LinkButton,
} from '@phpcreation/frontend-components-react';
import useTranslation from '@/hooks/shared/translation/useTranslation';
import useModalsStore from '@/lib/global-stores/shared/modals/useModalsStore';
import useTourStore from '@/lib/global-stores/shared/tours/useTourStore';
import { getFQCN } from '@/lib/helpers/fqcn/methods';
import { IFQCN_BUI } from '@/utils/types/common';
import Link from 'next/link';
import React, { FC } from 'react';

type Props = {
  tourId: string;
  resource: string;
  fqcn_bui: IFQCN_BUI;
};

const SectionHeaderActions: FC<Props> = ({ tourId, resource, fqcn_bui }) => {
  const { t } = useTranslation();
  const { runTour } = useTourStore();
  const { setModalAdd } = useModalsStore();
  return (
    <div
      id={getFQCN(fqcn_bui, 'header')}
      className="ActionsMain absolute px-100 pt-0 top-2 right-0 md:right-0 justify-end md:flex grid grid-cols-2 items-center w-full md:w-auto md:gap-20 md:space-x-24 lg:space-x-80 "
    >
      <div className=""></div>
      <div className="flex md:space-x-20 space-x-6 pl-10 mr-10  px-2 pt-1">
        {
          // Tour functionnality has been postponed
          /*
          <Button
            variant="secondary"
            icon={<IconPlay className="w-4 h-4 text-blue-login" />}
            onClick={() => {
              console.log(tourId);
              runTour(tourId);
            }}
            title="Tour"
            id={getFQCN(fqcn_bui, 'header', 'tour-button')}
          />
          */
        }

        {/* <Button
          title="Add"
          variant="primary"
          onClick={() => {
            setModalAdd(true);
          }}
          icon={<IconAddCircle className="w-4 h-4 text-white" />}
        /> */}

        <Dropdown
          // style={{marginLeft:"500px"}}
          position="right"
          useChevron
          icon={
            <IconGlyphPlus fill="white" className="w-4 h-4 text-gray-100" />
          }
          title="add"
          id={getFQCN(fqcn_bui, 'header', 'add')}
          size="small"
          bgcolor="blue-phpr"
          textcolor="gray-50"
        >
          <div className="flex flex-col  space-y-2 p-3">
            <Button
              id={getFQCN(fqcn_bui, 'header', 'add-button')}
              title={t('Quick')}
              onClick={() => {
                setModalAdd(true);
              }}
              icon={<IconLightning className="w-4 h-4 text-white" />}
              size="xsmall"
              className="rounded-md"
            />
            <Link href={'/' + resource + '/new'}>
              <Button
                id={getFQCN(fqcn_bui, 'header', 'add-link')}
                title={t(`detailed`)}
                variant="secondary"
                icon={<IconTenant className="w-4 h-4 " />}
                size="xsmall"
                className="rounded-md"
                onClick={() => {}}
              />
            </Link>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default SectionHeaderActions;
