import '../styles/globals.css';
import '../styles/expander.css';
import '../styles/modal.css';
// import 'nextra-theme-docs/style.css';
import 'antd/dist/antd.css';

import '@phpcreation/frontend-components-react/build.css';

import type { AppProps } from 'next/app';
import React from 'react';
import { AppContextProvider } from '..';

// For testing components
const endpoints = {
  crud: 'https://dev-api.config.solidservice.link/api/v1',
  auth: 'https://dev-api.config.solidservice.link/api/login_check',
  status: 'https://dev-api.config.solidservice.link/status',
  documentation: 'https://dev.config.solidservice.link/api/v1',
};

// For testing components
const locales = {
  en: {
    Hostnames: 'Hostnames',
    hostnames: 'hostnames',
    Hostname: 'Hostname',
    hostname: 'hostname',
    'hostnames ID': 'Hostname ID',
    'Hostname ID': 'Hostname ID',

    Tenants: 'Tenants',
    tenants: 'tenants',
    Tenant: 'Tenant',
    tenant: 'tenant',
    'tenant.id': 'tenant',
    'tenants ID': 'Tenant ID',
    'Tenant ID': 'Tenant ID',

    Parameters: 'Parameters',
    parameters: 'parameters',
    Parameter: 'Parameter',
    parameter: 'parameter',
    'parameters ID': 'Parameter ID',
    'Parameter ID': 'Parameter ID',

    'Our users can use this plateform to manage the tenants, hostnames ,paramters and much more features to discover!':
      'Our users can use this plateform to manage the tenants, hostnames , paramters and much more features to discover!',
    'Manage tenants': 'Manage tenants',
    'Manage hostnames': 'Manage hostnames',
    'Change parameters': 'Change parameters',
    'Overview of the tenants, simple way to manage every aspect.':
      'Overview of the tenants, simple way to manage every aspect.',
    'Configure hostnames, search hostnames and more features.':
      'Configure hostnames, search hostnames and more features.',
    'Manage parameters tied to each tenant, easy way to filter and configure.':
      'Manage parameters tied to each tenant, easy way to filter and configure.',

    'Select a tenant id': 'Select a tenant id',

    'hostnames Listing': 'Hostnames Listing',
    'tenants Listing': 'Tenants Listing',
    'parameters Listing': 'Parameters Listing',

    'Select tenant ID': 'Select tenant ID',
    'Search hostname': 'Search hostname',
    'Search tenant name': 'Search tenant name',

    'input the hostname': 'input the hostname',
  },
  fr: {
    Hostnames: "Noms d'hôtes",
    hostnames: "noms d'hôtes",
    Hostname: "Nom d'hôte",
    hostname: "nom d'hôte",
    'hostnames ID': "ID du nom d'hôte",
    'Hostname ID': "ID du nom d'hôte",

    Tenants: 'Comptes',
    tenants: 'comptes',
    Tenant: 'Compte',
    tenant: 'compte',
    'tenant.id': 'compte',
    'tenants ID': 'ID du compte',
    'Tenant ID': 'ID du compte',

    Parameters: 'Paramètres',
    parameters: 'paramètres',
    Parameter: 'Paramètre',
    parameter: 'paramètre',
    'parameters ID': 'ID du paramètre',
    'Parameter ID': 'ID du paramètre',

    'Our users can use this plateform to manage the tenants, hostnames ,paramters and much more features to discover!':
      "Nos utilisateurs peuvent utiliser cette plateforme pour gérer les comptes, les noms d'hôtes, les paramètres et bien d'autres fonctionnalités!",
    'Manage tenants': 'Gestion des comptes',
    'Manage hostnames': "Gestion des noms d'hôtes",
    'Change parameters': 'Gestion des paramètres',
    'Overview of the tenants, simple way to manage every aspect.':
      "Vue d'ensemble des comptes, moyen simple de gérer tous les aspects.",
    'Configure hostnames, search hostnames and more features.':
      "Configuration des noms d'hôtes, recherche de noms d'hôtes et autres fonctionnalités.",
    'Manage parameters tied to each tenant, easy way to filter and configure.':
      'Gestion des paramètres liés à chaque comptes, moyen facile de filtrer et de configurer.',

    'Select a tenant id': 'sélectionné un compte',

    'hostnames Listing': "Liste des noms d'hôtes",
    'tenants Listing': 'Liste des comptes',
    'parameters Listing': 'Liste des paramètres',

    'Select tenant ID': 'Sélectionné un ID de compte',
    'Search hostname': "Chercher par nom d'hôte",
    'Search tenant name': 'Chercher par nom de compte',

    'input the hostname': "saisir le nom d'hôte",
  },
};

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <AppContextProvider endpoints={endpoints} extraMessages={locales}>
      <Component {...pageProps} />
    </AppContextProvider>
  );
}
// export default MyApp;

export default MyApp;
