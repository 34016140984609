import {
  IconMenu,
  Button,
  Dropdown,
  Input,
} from '@phpcreation/frontend-components-react';
import useTranslation from '@/hooks/shared/translation/useTranslation';
import useListingStore from '@/lib/global-stores/shared/listing/useListingStore';
import { getFQCN } from '@/lib/helpers/fqcn/methods';
import { IFQCN_BUI } from '@/utils/types/common';
import { useRouter } from 'next/router';
import React, { FC, useEffect, useState } from 'react';

type Props = {
  resource: string;
  defaultFields: string[];
  fqcn_bui: IFQCN_BUI;
  selectedList: string[];
};

const DropdownFields: FC<Props> = ({
  defaultFields,
  resource,
  fqcn_bui,
  selectedList,
}) => {
  const { fields, setFields } = useListingStore();

  //translate
  const { t } = useTranslation();

  //@ts-ignore
  const [selected, setSelected] = useState<string[]>(selectedList);

  const router = useRouter();
  useEffect(() => {
    // setSelected(selectedList)
    if (router.isReady) {
      setFields(
        router.query.fields
          ? router.query.fields.toString().split(',')
          : selectedList
      );

      setSelected(
        router.query.fields
          ? router.query.fields.toString().split(',')
          : selected //defaultFields
      );
    }
  }, [router.query]);

  return (
    <Dropdown
      position="right"
      useChevron
      icon={<IconMenu className="w-4 h-4 text-gray-500" />}
      title="fileds"
      id={getFQCN(fqcn_bui, 'fields')}
      size="medium"
    >
      <div
        className="flex flex-col  space-y-2"
        style={{
          overflowY: defaultFields.length > 10 ? 'scroll' : 'clip',
          height: defaultFields.length > 10 ? '500px' : 'auto',
          //  transformOrigin: defaultFields.length > 10 ? 'top' : 'auto',
          //  top: 'auto'
        }}
      >
        <span
          id={getFQCN(fqcn_bui, 'fields', 'select-text')}
          onClick={() => console.log(fields)}
          className="text-xs text-gray-600 border-b  pb-1   mb-2 mx-2 "
        >
          {t('Selected fields')}
        </span>
        <div className="text-gray-600 flex-col  space-y-2 divide-y px-2">
          {defaultFields.map(item => (
            <div
              key={defaultFields.indexOf(t(item))}
              className="__Row_1 flex  space-x-3  items-center   "
            >
              <div className="w-5  flex justify-start   ">
                <Input
                  id={getFQCN(
                    fqcn_bui,
                    'fields',
                    item.toLocaleLowerCase() + '-checkbox'
                  )}
                  checked={selected.includes(item)}
                  onChange={() => {
                    if (selected?.includes(item))
                      setSelected(selected.filter(e => e !== item));
                    else setSelected([...selected, item]);
                  }}
                  // size="small"
                  type="checkbox"
                />
              </div>
              <span className="font-medium text-gray-500 text-sm pb-1">
                {t(item)}
              </span>
            </div>
          ))}
        </div>

        <div className="w-full px-3 py-2">
          <Button
            style={{ marginTop: '10px', marginBottom: '10px' }}
            id={getFQCN(fqcn_bui, 'fields', 'apply-button')}
            title={t('apply')}
            size="xsmall"
            onClick={() => setFields(selected)}
            className="w-full rounded-md"
          />
        </div>
      </div>
    </Dropdown>
  );
};

export default DropdownFields;
